<template>
  <div id="contacts-popup" class="contacts-popup" v-on:click.self="close">
    <div class="contacts-popup__wrapper" :class="[classErrors, classSuccess]">
      <span class="contacts__close" v-on:click="close"></span>
      <div class="contacts-popup__title" v-html="content.contacts_form_title"></div>
      <form class="contacts-popup__form" action="#" autocomplete="off">
        <p class="contacts-popup__item contacts-popup__item-name">
          <input
            class="contacts-popup__input"
            name="contacts-popup-name"
            type="text"
            required=""
            v-model="form.your_name"
          />
          <label class="contacts-popup__label">{{ content.contacts_form_name }}</label>
        </p>
        <p class="contacts-popup__item contacts-popup__item-email">
          <input
            class="contacts-popup__input"
            name="contacts-popup-email"
            type="text"
            required=""
            v-model="form.your_email"
          />
          <label class="contacts-popup__label">{{ content.contacts_form_mail }}</label>
        </p>
        <p class="contacts-popup__item contacts-popup__item-phone">
          <input
            class="contacts-popup__input"
            name="contacts-popup-phone"
            type="text"
            required=""
            v-model="form.your_phone"
          />
          <label class="contacts-popup__label">{{ content.contacts_form_phone }}</label>
        </p>
        <label class="contacts-popup__privacy-policy">
          <input type="checkbox" class="contacts-popup__checkbox visually-hidden" v-model="form.your_privacyPolicy">
          <span class="custom-checkbox"></span>
          {{ content.contacts_form_policy }}
        </label>
        <button class="contacts-popup__button" v-on:click.prevent="submit"><span class="contacts-popup__button-text">{{ content.contacts_form_button }}</span></button>
      </form>
      <div class="contacts-popup__message" v-html="content.contacts_form_success"></div>
    </div>
  </div>
</template>

<script>
import { validation } from "@/helpers/index.js";

export default {
  data() {
    return {
      content: this.$store.state.data.contentResponse.contacts.contacts_form,
      form: {
        id: 1038,
        your_name: "",
        your_email: "",
        your_phone: "",
        your_privacyPolicy: true,
      },
      classErrors: [],
      classSuccess: [],
    };
  },
  methods: {
    close(){
      this.$store.commit("toggleContactPopup", false);
    },
    submit() {
      this.classErrors = [];

      if (!validation(this.form.your_name.length)) {
        this.classErrors.push('error-name');
      }

      if (!validation(this.form.your_email.length)) {
        this.classErrors.push('error-email');
      }

      if (!validation(this.form.your_phone.length)) {
        this.classErrors.push('error-phone');
      }

      if (!this.form.your_privacyPolicy) {
        this.classErrors.push('error-privacy-policy');
      }

      if (!this.classErrors.length) {
        this.$store.dispatch("submitForm", this.form);
        this.classSuccess.push('success');

        setTimeout(() => {
          this.$store.commit("toggleContactPopup");
        }, 3000);
      }
    },
  },
  mounted() {
    console.log(this.content)
  }
};
</script>

<style>
.contacts-popup-enter-active,
.contacts-popup-leave-active {
  transition: opacity 0.5s ease;
}

.contacts-popup-enter-from,
.contacts-popup-leave-to {
  opacity: 0;
}

.contacts-popup {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  background-color: rgba(0, 0, 0, 0.6);
  user-select: none;
}

.contacts-popup__wrapper {
  position: absolute;
  top: 0;
  right: 0;

  max-width: 320px;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  padding: 80px 15px 80px 30px;

  background-color: #000000;

  transform: translate(0);
  transition: transform 0.2s ease;
}

.contacts-popup-enter-active .contacts-popup__wrapper,
.contacts-popup-leave-active .contacts-popup__wrapper {
  transition: transform 0.4s ease;
}

.contacts-popup-enter-from .contacts-popup__wrapper,
.contacts-popup-leave-to .contacts-popup__wrapper {
  transform: translate(110%);
}

.contacts__close {
  position: absolute;
  top: 15px;
  right: 20px;

  width: 22px;
  height: 22px;

  cursor: pointer;
}

.contacts__close::before {
  content: '';

  position: absolute;
  top: 10px;
  left: -4px;

  width: 30px;
  height: 1px;

  background-color: #ffffff;
  
  transform: rotate(45deg);
}

.contacts__close::after {
  content: '';

  position: absolute;
  top: 10px;
  left: -4px;

  width: 30px;
  height: 1px;

  background-color: #ffffff;

  transform: rotate(-45deg);
}

.contacts-popup__title {
  margin-bottom: 35px;
  
  font-weight: 700;
  font-size: 24px;
  line-height: 1.5;
}

.contacts-popup__form {
  opacity: 1;

  transition: opacity 0.5s;
}

.success .contacts-popup__form {
  position: absolute;
  z-index: -1;

  opacity: 0;
}

.contacts-popup__item {
  position: relative;

  margin-bottom: 30px;
}

.contacts-popup__input {
  width: 100%;
  height: 33px;
  border: none;
  border-bottom: 1px solid #363636;

  font-size: 12px;  
  color: #ffffff;

  background-color: transparent;

  transition: all 0.2s ease-in;
  appearance: none;
}

.contacts-popup__input:focus {
  outline: none;
}

.contacts-popup__label {
  position: absolute;
  top: 0;
  left: 0;

  font-size: 12px;  
  line-height: 1.5;
  color: #707070;

  text-transform: uppercase;

  transition: all 0.2s ease-in;
  pointer-events: none;
}

.contacts-popup__input:focus ~ .contacts-popup__label,
.contacts-popup__input:valid ~ .contacts-popup__label {
  font-size: 10px;  

  transform: translateY(-100%);
}

.error-name .contacts-popup__item-name .contacts-popup__input,
.error-email .contacts-popup__item-email .contacts-popup__input,
.error-phone .contacts-popup__item-phone .contacts-popup__input {
  border-bottom-color: #ff2600;
}

.error-name .contacts-popup__item-name .contacts-popup__label,
.error-email .contacts-popup__item-email .contacts-popup__label,
.error-phone .contacts-popup__item-phone .contacts-popup__label {
  color: #ff2600;
}

.contacts-popup__privacy-policy {
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  font-size: 12px;
  line-height: 1.5;

  text-transform: uppercase;

  color: #707070;

  user-select: none;
  cursor: pointer;

  transition: all 0.2s ease-in;
}

.custom-checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border: 1px solid #ffffff;
  border-radius: 100%;
  margin-right: 15px;

  transition: all 0.2s ease-in;
}

.custom-checkbox::before {
  content: "";

  width: 7px;
  height: 7px;

  background-image: url(../../assets/img/checkbox.png);
  background-repeat: repeat;

  transform: scale(0);
  transition: transform 0.2s ease-in;
}

.contacts-popup__checkbox:checked ~ .custom-checkbox::before {
  transform: scale(1);
}

.error-privacy-policy .contacts-popup__privacy-policy {
  color: #ff2600;
}

.error-privacy-policy .custom-checkbox {
  border-color: #ff2600;
}

.contacts-popup__button {
  display: flex;
  align-items: center;

  border: none;

  font-family: Montserrat, Tahoma, sans-serif;
  font-size: 20px;
  text-transform: uppercase;
  color: #ffffff;

  background-color: transparent;

  cursor: pointer;
  user-select: none;
  appearance: none;
  overflow: hidden;
}

.contacts-popup__button::before {
  content: "";

  display: block;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  margin-right: -20px;

  background-image:url("../../assets/img/gradient-button.png");
  background-repeat: no-repeat;
  background-size: cover;

  transition: transform 0.3s ease-in;
}

.contacts-popup__button-text {
  position: relative;
  z-index: 1;
}

.contacts-popup__button:hover::before {
  transform: scale(0.9) rotate(20deg);
}

.contacts-popup__message {
  position: absolute;
  z-index: -1;
  
  font-size: 16px;

  opacity: 0;

  transition: opacity 0.8s;
}

.success .contacts-popup__message {
  opacity: 1;
}


@media (max-width: 1599.98px) {
  .contacts-popup__button {
    font-size: 18px;
  }
}

@media screen and (max-width: 1199.98px) {
  .contacts-popup__button {
    font-size: 16px;
  }
}

@media screen and (max-width: 767.98px) {
  .contacts-popup__wrapper {
    max-width: none;
    padding: 80px 15px;
  }
}
</style>
