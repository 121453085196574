<template>
  <p :class="classObject">
    <slot />
  </p>
</template>

<script>
export default {
  props: [
    "parentClass"
  ],
  data() {
    return {
      classObject: {
        "subtitle-2": true,
        [this.parentClass]: this.parentClass,
      },
    };
  },
};
</script>

<style>
.subtitle-2 {
  font-size: 48px;
  font-weight: 700;
  line-height: 1.5;
}

.subtitle-2__line {
  line-height: 1.2;
}

@media screen and (max-width: 1599.98px) {
  .subtitle-2 {
    font-size: 38px;
  }
}

@media screen and (max-width: 1199.98px) {
  .subtitle-2 {
    font-size: 32px;
  }
}

@media screen and (max-width: 991.98px) {
  .subtitle-2 {
    font-size: 24px;
  }
}

@media screen and (max-width: 767.98px) {
  .subtitle-2 {
    font-size: 20px;
  }
}
</style>