<template>
  <div :class="classObject">
    <slot />
  </div>
</template>

<script>
export default {
  props: [
    "parentClass"
  ],
  data() {
    return {
      classObject: {
        'title-2': true,
        [this.parentClass]: this.parentClass,
      },
    };
  },
};
</script>

<style>
.title-2 {
  font-size: 600px;
  line-height: 1.2;
  color: #ffffff;

  user-select: none;
}

@media screen and (max-width: 1599.98px) {
  .title-2 {
    font-size: 500px;
  }
}

@media screen and (max-width: 1199.98px) {
  .title-2 {
    font-size: 400px;
  }
}

@media screen and (max-width: 991.98px) {
  .title-2 {
    font-size: 300px;
  }
}

@media screen and (max-width: 767.98px) {
  .title-2 {
    font-size: 200px;
  }
}
</style>