<template>
  <div class="preloader">
    <div class="preloader__left">
      <svg class="preloader__left-icon" width="282" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 198.3" fill="#FFFFFF">
        <polygon points="1076.4,6.6 969.2,198.2 841.5,198.2 731.4,9.7 831.4,8.8 872.4,82.4 905.7,145.9 939.3,81.8 979.5,7.5"/>
        <path d="M1346,198.2h-190.3c-44.3-20.5-65.7-49.9-66.1-93.8c-0.5-60.2,47.5-103,130.2-103.8c51.5-0.5,99.1,15.4,136.6,42.6 l-37.7,61.6c-24.7-18.7-62-31.1-95.2-30.8c-29.1,0.3-47.4,11.1-47.2,28.5c0.1,17.4,15.1,27.9,68.2,42.8 C1292.8,158.3,1326.3,175.2,1346,198.2z"/>
        <path d="M591.1,11L441,12.3l1.7,185.9h268.2c12-18.6,18.3-41.3,18-67.6C728.3,58.2,673.8,10.2,591.1,11z M584,186.6l-55.1,0.5 L528,83.5l55-0.5c37.3-0.3,57.8,18.9,58.2,51.5C641.5,167.2,621.2,186.2,584,186.6z"/>
        <path d="M392.7,140.9c0.2,21.3-3.6,40.6-10.9,57.4h-183l46.9-0.4c37.3-0.3,59-20.9,58.7-55.1c-0.3-34.7-22.4-54.4-59.7-54.1 l-47,0.4l1,109.2h-86.3l-1.6-183L253.2,14C339.5,13.2,392,61.7,392.7,140.9z"/> 
        <polygon points="1799.8,198.2 1712.5,198.2 1712,138.1 1669.7,198.2 1549.9,198.2 1507.3,140 1507.9,198.2 1421.1,198.2 1419.3,3.5 1496.9,2.8 1609.6,153.3 1719.9,0.7 1798,0 "/>
      </svg>
    </div>
    <div class="preloader__right">
      <svg class="preloader__right-icon" width="282" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 173.5" fill="#FFFFFF">
        <polygon points="1585.1,0 1704.8,0 1665.5,56 1626.2,56.3"/>
        <path d="M697.2,40.7l80.3,127.9l-95.4,0.9L613.7,54.8l-47.5,0.4l1,115.3l-87.8,0.8L477.8,0H746C734.7,17.6,718.2,31.4,697.2,40.7z"/>
        <path d="M1381.1,0c14.3,16.8,21.4,36.8,21.6,60.9c0.6,63.8-55.7,107.2-141.4,108c-55.1,0.5-108.9-16.3-149.4-46.6l37.1-63.7 c35.4,24.2,70.7,35.6,109.5,35.2c35.7-0.3,56-11.7,55.9-31.6c-0.2-18.9-19.2-30.4-72.9-44.8c-19.4-5-36.3-10.7-50.7-17.4L1381.1,0 L1381.1,0z"/>
        <polygon points="1543,0 1544.5,161.7 1457.7,162.5 1456.3,0 "/>
        <polygon points="1836.4,159 1749.1,159.9 1747.7,0 1835,0 "/>
        <path d="M417,0c-19.4,44.4-63.7,71.4-126.3,72l-54.6,0.5l0.9,101l-87.8,0.8L147.6,0L417,0L417,0z"/>
        <polygon points="1004.3,0 985,34.6 986.2,166.7 898.4,167.5 897.2,35.4 876.6,0 "/>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.body.style.overflow = "hidden";
  },
  unmounted() {
    setTimeout(() => {
      document.body.style.overflow = "visible";
      document.body.classList.add("start");
    }, 1400);
  },
};
</script>

<style scoped>
.preloader {
  position: fixed;
  z-index: 9999999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  width: 100vw;
  height: 100vh;
}

.preloader__left {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 50%;

  background-color: #000000;
  overflow: hidden;
}

.preloader-enter-active .preloader__left,
.preloader-leave-active .preloader__left {
  animation: preloader-left 1s 0.8s ease forwards;
  will-change: transform;
}

@keyframes preloader-left{
  from { 
    transform: translate3d(0, 0, 0);
  }
  to { 
    transform: translate3d(0, -110%, 0);
  }
}

.preloader__left-icon {
  opacity: 0;

  animation: preloader-left-icon 0.6s ease-out forwards;
}

@keyframes preloader-left-icon{
  from { opacity: 0;}
  to { opacity: 1;}
}

.preloader__right {
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 50%;

  background-color: #000000;
  overflow: hidden;
}

.preloader-enter-active .preloader__right,
.preloader-leave-active .preloader__right {
  animation: preloader-right 1s 0.8s ease forwards;
  will-change: transform;
}

@keyframes preloader-right{
  from { transform: translate3d(0, 0, 0);}
  to { transform: translate3d(0, 110%, 0);}
}

.preloader__right-icon {
  opacity: 0;
  animation: preloader-right-icon 0.6s ease-out forwards;
}

@keyframes preloader-right-icon{
  from { opacity: 0;}
  to { opacity: 1;}
}

@media (max-width: 1199.98px) {
  .preloader__left-icon {
    width: 250px;
  }

  .preloader__right-icon {
    width: 250px;
  }
}

@media (max-width: 991.98px) {
  .preloader__left-icon {
    width: 230px;
  }

  .preloader__right-icon {
    width: 230px;
  }
}

@media (max-width: 767.98px) {
  .preloader__left-icon {
    width: 190px;
  }

  .preloader__right-icon {
    width: 190px;
  }
}
</style>