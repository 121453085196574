<template>
  <div :class="classObject" v-html="logo"></div>
</template>

<script>
export default { 
  props: [
    "logo", 
    "parentClass"
  ],
  data() {
    return {
      classObject: {
        logo: true,
        [this.parentClass]: this.parentClass,
      },
    };
  },
};
</script>

<style>
.logo {
  width: 150px;
  height: 33px;
}

.logo svg {
  display: block;
}

.logo path {
  transition: fill 0.2s ease-in;
}

.light .logo path {
  fill: #ffffff;
}

.mobile .logo path {
  fill: #ffffff;

  transition: fill 0.7s ease-in;
}

@media (max-width: 1599.98px) {
  .logo {
    width: 103px;
    height: 22px;
  }
}

@media (max-width: 1199.98px) {
  .logo {
    width: 80px;
    height: 17px;
  }
}
</style>
