<template>
  <p :class="classObject">
    <slot />
  </p>
</template>

<script>
export default {
  props: [
    "parentClass"
  ],
  data() {
    return {
      classObject: {
        "subtitle-4": true,
        [this.parentClass]: this.parentClass,
      },
    };
  },
};
</script>

<style>
.subtitle-4 {
  font-weight: 700;
  font-size: 88px;
  line-height: 1.5;
}

@media (max-width: 1599.98px) {
  .subtitle-4 {
    font-size: 64px;
  }
}

@media (max-width: 1199.98px) {
  .subtitle-4 {
    font-size: 48px;
  }
}

@media (max-width: 991.98px) {
  .subtitle-4 {
    font-size: 40px;
  }
}

@media (max-width: 767.98px) {
  .subtitle-4 {
    font-size: 36px;
  }
}

</style>