import { createApp } from "vue";
import App from "./App.vue";
import Store from "@/store/index.js";
import mediaWp from "@/plugins/mediaWp.js";

import "@/assets/css/st-global-v1.css";

let app = createApp(App)
  .use(Store)
  .use(mediaWp)

app.mount("#app");
