<template>
  <div :class="classObject">
    <span class="burger__item burger__item_top"></span>
    <span class="burger__item burger__item_bottom"></span>
  </div>
</template>

<script>
export default {
  props: [
    "parentClass"
  ],
  data() {
    return {
      classObject: {
        burger: true,
        [this.parentClass]: this.parentClass,
      },
    };
  },
};
</script>

<style>
.burger {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  width: 30px;
  height: 8px;

  cursor: pointer;
  user-select: none;
}

.burger__item {
  display: block;
  width: 30px;
  height: 2px;

  background-color: #000000;

  transition: transform 0.2s ease-out;
  transition-property:transform, background-color;
}

.burger__item:last-child {
  margin-bottom: 0px;
}

.light .burger__item {
  background-color: #ffffff;
}

.mobile .burger__item_top {
  background-color: #ffffff;

  transform: translateY(3px) rotate(-135deg);
}

.mobile .burger__item_bottom {
  background-color: #ffffff;

  transform: translateY(-3px) rotate(135deg);
}
</style>
