<template>
  <section class="partners">
    <ul class="partners__list" v-for="item in 3" :key="item">
      <li class="partners__item" v-for="item in content.partners_list" :key="item">
        <picture>
          <source :srcset="$mediaWp(item)" media="(max-width: 767.98px)">
          <source :srcset="$mediaWp(item)" media="(max-width: 1199.98px)">
          <img class="partners__img" :src="$mediaWp(item, 'partners')" alt=""/>
        </picture>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  data() {
    return {
      content: this.$store.state.data.contentResponse.partners,
    };
  },
};
</script>

<style>
.partners {
  display: flex;
  padding-top: 100px;
  padding-bottom: 350px;

  background-color: #000000;

  overflow: hidden;
  user-select: none;
}

.partners__list {
  display: flex;
  flex-shrink: 0;
  animation: marquee 15s linear infinite both;
}

@keyframes marquee {
  100% {
    transform: translateX(-100%);
  }
}

.partners__item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 409px;
  height: 126px;
  border-left: 1px solid #363636;
}

.partners__img {
  max-height: 100%;
}

@media (max-width: 1599.98px) {
  .partners {
    padding-top: 50px;
    padding-bottom: 300px;
  }

  .partners__item {
    width: 370px;
    height: 120px;
  }
}

@media (max-width: 1199.98px) {
  .partners {
    padding-top: 40px;
    padding-bottom: 250px;
  }

  .partners__item {
    width: 260px;
    height: 70px;
    padding: 0 20px;
  }
}

@media (max-width: 991.98px) {
  .partners {
    padding-top: 20px;
    padding-bottom: 200px;
  }

  .partners__item {
    width: 240px;
  }
}

@media (max-width: 767.98px) {
  .partners {
    padding-bottom: 180px;
  }

  .partners__item {
    width: 190px;
    height: 60px;
  }
}
</style>