<template>
  <p :class="classObject">
    <slot />
  </p>
</template>

<script>
export default {
  props: [
    "parentClass"
  ],
  data() {
    return {
      classObject: {
        "subtitle-1": true,
        [this.parentClass]: this.parentClass,
      },
    };
  },
};
</script>

<style>
.subtitle-1__dark {
  color: #000000;
}

.subtitle-1_big {
  text-transform: uppercase;
  line-height: 1.5;
}
</style>