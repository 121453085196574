<template>
  <section class="сontacts" id="contact">
    <div class="container">
      <div class="сontacts_content" data-scroll data-scroll-offset="15%">
        <Subtitle4 parent-class="сontacts__subtitle" v-html="subtitle"></Subtitle4>
        <button class="сontacts__button fade-custom" v-on:click="openPopup"
          ><span class="сontacts__button-text">{{ content.contacts_button }}</span></button
        >
      </div>
      <ul class="сontacts__list" v-if="!content.disable_command">
        <li class="сontacts__item" v-for="item in content.contacts_list" :key="item.contacts_img" data-scroll data-scroll-speed="2">
          <picture>
            <source :srcset="$mediaWp(item.contacts_img, 'contacts991')" media="(max-width: 991.98px)" />
            <source :srcset="$mediaWp(item.contacts_img, 'contacts1199')" media="(max-width: 1199.98px)" />
            <source :srcset="$mediaWp(item.contacts_img, 'contacts1599')" media="(max-width: 1599.98px)" />
            <img class="сontacts__img" :src="$mediaWp(item.contacts_img, 'contacts')" data-scroll alt="" />
          </picture>
          <p class="сontacts__name">{{ item.contacts_name }}</p>
          <a class="сontacts__email" :href="'mailto:' + item.contacts_email" target="_blank">{{ item.contacts_email }}</a>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import Subtitle4 from "@/components/element/Subtitle4App.vue";

export default {
  components: {
    Subtitle4,
  },
  data() {
    return {
      content: this.$store.state.data.contentResponse.contacts,
    };
  },
  computed: {
    subtitle() {
      let text = this.content.contacts_description.split(" ");
      text = text.map((item) => '<span class="text-element"><span class="slide-custom">' + item + "</span></span>");
      text = text.join(" ");

      return text;
    },
  },
  methods: {
    openPopup() {
      this.$store.commit("toggleContactPopup", true);
    },
  },
};
</script>

<style>
.сontacts {
  padding-top: 150px;
  padding-bottom: 300px;

  background-color: #000000;
}

.сontacts__subtitle {
  width: 1060px;
}

.сontacts__button {
  width: max-content;
  margin: -50px auto 115px;

  overflow: hidden;

  display: flex;
  align-items: center;
  border: none;

  font-family: Montserrat, Tahoma, sans-serif;
  font-size: 20px;
  text-transform: uppercase;
  color: #ffffff;

  background-color: transparent;

  cursor: pointer;
  user-select: none;
  appearance: none;
  overflow: hidden;
}

.сontacts__button::before {
  content: "";

  display: block;
  width: 140px;
  height: 140px;
  border-radius: 100%;
  margin-right: -75px;

  background-image: url("../../assets/img/gradient-button.png");
  background-repeat: no-repeat;
  background-size: cover;

  transition: transform 0.3s ease-in;
}

.сontacts__button:hover::before {
  transform: scale(0.9) rotate(20deg);
}

.сontacts__button-text {
  position: relative;
  z-index: 1;
}

.сontacts__list {
  display: grid;
  grid-template-columns: 410px 410px;
  grid-template-rows: 1fr;
  justify-content: space-around;
  gap: 50px 0px;
}

.сontacts__item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.сontacts__img {
  margin-bottom: 50px;

  transform: scale(1.4, 1.4);
  transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-delay: 0.1s;

  overflow: hidden;
}

.сontacts__img.is-inview {
  transform: scale(1, 1);
}

.сontacts__name {
  margin-bottom: 5px;

  text-transform: uppercase;
  color: transparent;

  background-clip: text;
}

.сontacts__item:nth-child(odd) .сontacts__name {
  background-image: linear-gradient(135deg, #eeac78 20%, #f0967c 40%, #ed5398 60%, #f282b8 80%, #ffffff 100%);
}

.сontacts__item:nth-child(even) .сontacts__name {
  background-image: linear-gradient(135deg, #239ba7 20%, #54a09f 40%, #dfc3a6 60%, #f5a180 80%, #f0776d 100%);
}

.сontacts__email {
  text-transform: uppercase;
  text-decoration: none;
  color: #ffffff;
}

@media screen and (max-width: 1599.98px) {
  .сontacts {
    padding-top: 125px;
    padding-bottom: 250px;
  }

  .сontacts__subtitle {
    width: 770px;
  }

  .сontacts__button {
    margin-top: -40px;
    margin-bottom: 72px;

    font-size: 18px;
  }

  .сontacts__button::before {
    width: 120px;
    height: 120px;
    margin-right: -65px;
  }

  .сontacts__list {
    display: grid;
    grid-template-columns: 332px 332px;
  }

  .сontacts__img {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 1199.98px) {
  .сontacts {
    padding-top: 100px;
    padding-bottom: 200px;
  }

  .сontacts__subtitle {
    width: 632px;
  }

  .сontacts__button {
    margin-top: -45px;
    margin-bottom: 76px;

    font-size: 16px;
  }

  .сontacts__button::before {
    width: 100px;
    height: 100px;
    margin-right: -55px;
  }

  .сontacts__list {
    display: grid;
    grid-template-columns: 296px 296px;
  }

  .сontacts__img {
    margin-bottom: 35px;
  }
}

@media screen and (max-width: 991.98px) {
  .сontacts {
    padding-top: 90px;
    padding-bottom: 180px;
  }

  .сontacts__subtitle {
    width: 490px;
    margin-bottom: 30px;
  }

  .сontacts__button {
    margin-top: 0;
    margin-bottom: 100px;

    font-size: 14px;
  }

  .сontacts__button::before {
    width: 80px;
    height: 80px;
    margin-right: -40px;
  }

  .сontacts__list {
    display: grid;
    grid-template-columns: 220px 220px;
  }

  .сontacts__img {
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 767.98px) {
  .сontacts {
    padding-top: 80px;
    padding-bottom: 160px;
  }

  .сontacts__subtitle {
    width: 100%;
  }

  .сontacts__button {
    margin-bottom: 80px;
  }

  .сontacts__list {
    display: grid;
    grid-template-columns: 1fr;
  }

  .сontacts__item {
    width: 220px;
  }

  .сontacts__item:nth-child(odd) {
    justify-self: left;
  }

  .сontacts__item:nth-child(even) {
    justify-self: right;
  }
}
</style>
