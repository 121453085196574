export default {
  mounted: function () {
    function scrollHeader() {
      if (!window.scrollCustom) { 
        setTimeout(scrollHeader, 500);
        return;
      }

      window.scrollCustom.on('scroll', (e) => {
        const shiftDistance = document.querySelector('.offer_mainscreen').clientHeight;

        if (shiftDistance <= e.scroll.y) {
          document.body.classList.add('light')
        }

        if (shiftDistance >= e.scroll.y) {
          document.body.classList.remove('light')
        }
      });
    }

    scrollHeader()
  }
}