<template>
  <Transition name="preloader" :duration="1800">
    <Preloader v-if="this.$store.state.preloader"/>
  </Transition>
  <IndexPage v-if="this.$store.state.data" />
</template>

<script>
import Preloader from "@/components/section/PreloaderApp.vue";
import IndexPage from "@/views/IndexPage.vue";


export default {
  name: "App",
  components: {
    Preloader,
    IndexPage,
  },
  mounted() {
    this.$store.dispatch("loadingData");

    window.addEventListener('resize', () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  },
};
</script>

<style></style>
