<template>
  <div :class="classObject">
    <slot />
  </div>
</template>

<script>
export default {
  props: [
    "parentClass"
  ],
  data() {
    return {
      classObject: {
        'title-1': true,
        [this.parentClass]: this.parentClass,
      },
    };
  },
};
</script>

<style>
.title-1 {
  font-size: 88px;
  line-height: 1.1;
  color: #000000;
}

@media screen and (max-width: 1599.98px) {
  .title-1 {
    font-size: 64px;
  }
}

@media screen and (max-width: 1199.98px) {
  .title-1 {
    font-size: 48px;
  }
}

@media screen and (max-width: 991.98px) {
  .title-1 {
    font-size: 40px;
  }
}

@media screen and (max-width: 767.98px) {
  .title-1 {
    font-size: 36px;
  }
}
</style>