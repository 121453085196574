<template>
  <span :class="classObject">
    <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m1 15.002 8-7-8-7" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"/></svg>
  </span>
</template>

<script>
export default {
  props: [
    "parentClass"
  ],
  data() {
    return {
      classObject: {
        ["button-slide-next"]: true,
        [this.parentClass]: this.parentClass,
      },
    };
  },
};
</script>

<style>
.button-slide-next {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border: 1px solid #363636;
  border-radius: 50%;

  cursor: pointer;
  user-select: none;
}

@media (max-width: 991.98px) {
  .button-slide-next {
    width: 40px;
    height: 40px;
  }

  .button-slide-next svg {
    width: 6px;
    height: 11px;
  }
}
</style>