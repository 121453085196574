<template>
  <div class="project-popup" v-on:click.self="close">
    <div class="project-popup__wrapper" :style="'background-image: url(' + urlImages + ')'">
      <span class="project-popup__close" v-on:click="close"></span>
      <div class="project-popup__content">
        <Subtitle3 class="project-popup__text" v-html="content.projects_description"></Subtitle3>
        <ul class="project-popup__success">
          <li class="project-popup__success-item" v-for="subItem in content.projects_success" :key="subItem.projects_success_item">{{ subItem.projects_success_item }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Subtitle3 from "@/components/element/Subtitle3App.vue";

export default {
  components: {
    Subtitle3,
  },
  data() {
    return {
      content: this.$store.state.projectsPopup.data,
    };
  },
  computed: {
    urlImages() {
      return this.$mediaWp(this.content.projects_image, 'projects991');
    }
  },
  methods: {
    close(){
      this.$store.commit("toggleProjectsPopup", {status: false, data: null});
    },
  },
};
</script>

<style>
.projects-popup-enter-active,
.projects-popup-leave-active {
  transition: opacity 0.5s ease;
}

.projects-popup-enter-from,
.projects-popup-leave-to {
  opacity: 0;
  user-select: none;
}

.project-popup {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  background-color: rgba(0, 0, 0, 0.6);
}

.project-popup__wrapper {
  position: relative;

  max-width: 570px;
  width: 100%;

  background-color: #000000;
  background-repeat: no-repeat;
  background-size: cover;
}

.project-popup__close {
  position: absolute;
  top: 30px;
  right: 20px;
  z-index: 1;

  width: 20px;
  height: 20px;

  cursor: pointer;
}

.project-popup__close::before {
  content: '';

  position: absolute;
  top: 7px;
  left: -3px;

  width: 25px;
  height: 1px;

  background-color: #ffffff;
  
  transform: rotate(45deg);
}

.project-popup__close::after {
  content: '';

  position: absolute;
  top: 7px;
  left: -3px;

  width: 25px;
  height: 1px;

  background-color: #ffffff;

  transform: rotate(-45deg);
}

.project-popup__content {
  padding: 50px;
  
  backdrop-filter: blur(25px);
}

.project-popup__success {
  display: flex;
  flex-wrap: wrap;

  margin-right: -40px;
}

.project-popup__success-item {
  margin-right: 40px;
  margin-bottom: 5px;

  font-size: 18px;
  font-weight: 700;
  line-height: 1.8;
  color: transparent;    

	background-clip: text;
}

.project-popup__success-item:last-child {
  margin-bottom: 0;
}

.project-popup__success-item:nth-child(3n+1) {
  background-image: linear-gradient(135deg, #4F8376 20%, #978B63 40%, #F39F71 60%, #ED6476 80%, #E8347B 100%);
}

.project-popup__success-item:nth-child(3n-1) {
  background-image: linear-gradient(135deg, #EEAC78 20%, #F0967C 40%, #ED5398 60%, #F282B8 80%, #FFFFFF 100%);
}

.project-popup__success-item:nth-child(3n) {
  background-image: linear-gradient(135deg, #239BA7 20%, #54A09F 40%, #DFC3A6 60%, #F5A180 80%, #F0776D 100%);
}

@media screen and (max-width: 1199.98px) {
  .project-popup__wrapper {
    max-width: 470px;
  }

  .project-popup__success-item {
    font-size: 14px;
  }
}

@media screen and (max-width: 767.98px) {
  .project-popup__wrapper {
    max-width: 450px;
  }
}

@media screen and (max-width: 500.98px) {
  .project-popup__wrapper {
    width: 100%;
    height: 100%;
  }

  .project-popup__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
</style>
