<template>
  <section class="offer">
    <div class="offer_mainscreen">
      <div class="container">
        <div class="offer__content">
          <Title1 parent-class="offer__title" data-scroll v-html="title"></Title1>
          <Subtitle1 parent-class="offer__desc subtitle-1__dark" data-scroll v-html="subtitle"></Subtitle1>
        </div>
      </div>
      <div class="offer-mario mario-step-1" ref="mario"></div>
    </div>
    <div class="offer_bottom">
      <div class="offer_bottom-img-wrapper" data-scroll data-scroll-speed="-1.5">
        <picture>
          <source :srcset="$mediaWp(content.offer_image, 'offer991')" media="(max-width: 991.98px)">
          <source :srcset="$mediaWp(content.offer_image, 'offer1199')" media="(max-width: 1199.98px)">
          <source :srcset="$mediaWp(content.offer_image, 'offer1599')" media="(max-width: 1599.98px)">
          <img class="offer_bottom-img" :src="$mediaWp(content.offer_image, 'offer')" alt="" />
        </picture>
      </div>
    </div>
  </section>
</template>

<script>
import Title1 from "@/components/element/Title1App.vue";
import Subtitle1 from "@/components/element/Subtitle1App.vue";

export default {
  components: {
    Title1,
    Subtitle1
  },
  data() {
    return {
      content: this.$store.state.data.contentResponse.offer,
    };
  },
  computed: {
    title() {
      let arrayText = this.content.offer_title.split('|');

      arrayText = arrayText.map((item) => {
        let itemText = item.trim().split(' ');

        item = itemText.map((subItem) => {
          return '<span class="text-element"><span class="slide-custom">' + subItem + '</span></span>'
        });

        return item.join(' ');
      });

      arrayText[0] = '<div class="offer__title-first">'+ arrayText[0] +'</div>';
      arrayText[2] = '<div class="offer__title-last">'+ arrayText[2] +'</div>';

      return arrayText.join(' ') + '<div class="offer__title-hidden">' + this.content.offer_title_hidden + '</div>';
    },
    subtitle() {
      let text = this.content.offer_description.split(' ');
      text = text.map((item) => '<span class="text-element"><span class="slide-custom">' + item + '</span></span>');
      text = text.join(' ');
      
      return text;
    }
  },
  mounted(){
    const el = this.$refs.mario;
    
    el.addEventListener("animationend", () => {
      if(el.classList.contains('mario-step-4')) {
        el.classList.replace('mario-step-4', 'mario-step-5');
      } 

      if(el.classList.contains('mario-step-3')) {
        el.classList.replace('mario-step-3', 'mario-step-4');
      }  

      if(el.classList.contains('mario-step-2')) {
        el.classList.replace('mario-step-2', 'mario-step-3');
      }

      if(el.classList.contains('mario-step-1')) {
        el.classList.replace('mario-step-1', 'mario-step-2');

        setInterval(() => document.body.classList.add('hit'), 290);
      }
    });
  }
};
</script>

<style>
.offer_mainscreen {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 80vh;
  padding-top: 150px;
  padding-bottom: 150px;

  background-color: #ffffff;
}

.offer__content {
  width: 1250px;
}

.offer__title {
  margin-bottom: 40px;
  
  overflow: hidden;
}

.hit .offer__title {
  animation: title 0.3s ease-in forwards;
}

@keyframes title {
  0% {
   transform: translateY(0);
  }
  50% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0);
  }
}

.offer__title-first {
  display: inline;

  text-transform: uppercase;
  font-weight: 700;
  color: #000000;

  transition: color 0.3 ease-in;
}

.offer__title-first .slide-custom {
  background-clip: text;
  background-image: url('../../assets/img/offer-text-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.offer__title-last {
  display: inline;
}

.offer__title-hidden {
  display: none;
}

.hit .offer__title-first {
  color: transparent; 
}

.hit .offer__title-last {
  display: none;
}

.hit .offer__title-hidden {
  display: inline;
}

.offer__desc {
  width: 300px;
}

.offer-mario {
  position: absolute;
  left: -5%;
  bottom: 50px;

  width: 60px;
  height: 58px;
}

.start .mario-step-1 {
  background-image: url('../../assets/img/start-mario.png');
  animation: forward 0.1s steps(3) infinite, mario-step-1 1s 1.2s linear forwards;
}

.mario-step-2 {
  background-image: url('../../assets/img/jump-mario.png');
  animation: jump 0.8s steps(15) infinite, mario-step-2 0.8s linear forwards;
}

.mario-step-3 {
  background-image: url('../../assets/img/start-mario.png');
  animation: forward 0.1s steps(3) infinite, mario-step-3 0.7s linear forwards;
}

.mario-step-4 {
  background-image: url('../../assets/img/stop-mario.png');
  animation: stop 0.05s 0.6s steps(2) forwards, mario-step-4 0.7s linear forwards;
}

.mario-step-5 {
  background-image: url('../../assets/img/end-mario.png');
  animation: back 0.1s steps(3) infinite, mario-step-5 2s linear forwards;
}

@keyframes forward {
  100% {
    background-position: 180px;
  }
}

@keyframes jump {
  100% {
    background-position: 900px;
  }
}

@keyframes stop {
  100% {
    background-position: 120px;
  }
}

@keyframes back {
  100% {
    background-position: -180px;
  }
}

@keyframes mario-step-1 {
  100% {
    left: 30%;
  }
}

@keyframes mario-step-2 {
  0% {
    left: 30%;
    bottom: 50px;
  }
  50% {
    left: 40%;
    bottom: 40%;
  }
  100% {
    left: 50%;
    bottom: 50px;
  }
}

@keyframes mario-step-3 {
  0% {
    left: 50%;
  }
  100% {
    left: 65%;
  }
}

@keyframes mario-step-4 {
  0% {
    left: 65%;
  }
  100% {
    left: 65%;
  }
}

@keyframes mario-step-5 {
  0% {
    left: 65%;
  }
  100% {
    left: -20%;
  }
}

.offer_bottom {
  width: 100%;
  height: 933px;

  overflow: hidden;
}

.offer_bottom-img-wrapper {
  height: 70vh;
}

.offer_bottom-img {
  width: 100%;

  transform: scale(1.2, 1.2);
}

@media (max-width: 1599.98px) {
  .offer__content {
    width: 870px;
  }

  .offer__title {
    margin-bottom: 30px;
  }

  .start .mario-step-1 {
    animation-duration: 0.1s, 0.8s;
  }

  .mario-step-2 {
    animation-duration: 0.7s, 0.7s;
  }

  .mario-step-3 {
    animation-duration: 0.1s, 0.5s;
  }

  .mario-step-4 {
    animation-duration: 0.05s, 0.5s;
  }

  .mario-step-5 {
    animation-duration: 0.1s, 1.5s;
  }

  .offer_bottom {
    height: 583px;
  }
}

@media (max-width: 1199.98px) {
  .offer__content {
    width: 700px;
  }

  .offer__title {
    margin-bottom: 25px;
  }

  .offer__desc {
    width: 234px;
  }

  .offer-mario {
    left: -15%;
  }

  .start .mario-step-1 {
    animation-duration: 0.1s, 0.7s;
  }

  .mario-step-2 {
    animation-duration: 0.6s, 0.6s;
  }

  .mario-step-3 {
    animation-duration: 0.1s, 0.4s;
  }

  .mario-step-4 {
    animation-duration: 0.05s, 0.4s;
  }

  .mario-step-5 {
    animation-duration: 0.1s, 1.3s;
  }

  @keyframes mario-step-2 {
    0% {
      left: 30%;
      bottom: 50px;
    }
    50% {
      left: 39%;
      bottom: 42%;
    }
    100% {
      left: 50%;
      bottom: 50px;
    }
  }

  .offer_bottom {
    height: 499px;
  }
}

@media (max-width: 991.98px) {
  .offer__content {
    width: 590px;
  }

  .start .mario-step-1 {
    animation-duration: 0.1s, 0.6s;
  }

  .mario-step-2 {
    animation-duration: 0.6 s, 0.6s;
  }

  .mario-step-3 {
    animation-duration: 0.1s, 0.3s;
  }

  .mario-step-4 {
    animation-duration: 0.05s, 0.3s;
  }

  .mario-step-5 {
    animation-duration: 0.1s, 0.8s;
  }

  @keyframes mario-step-2 {
    0% {
      left: 30%;
      bottom: 50px;
    }
    50% {
      left: 42%;
      bottom: 42%;
    }
    100% {
      left: 50%;
      bottom: 50px;
    }
  }

  .offer_bottom {
    height: 371px;
  }
}

@media (max-width: 767.98px) {
  .offer__content {
    width: 100%;
  }

  .offer-mario {
    left: -20%;
  }

  .start .mario-step-1 {
    animation-duration: 0.1s, 0.5s;
  }

  .mario-step-2 {
    animation-duration: 0.5s, 0.5s;
  }

  .mario-step-3 {
    animation-duration: 0.1s, 0.2s;
  }

  .mario-step-4 {
    animation-duration: 0.05s, 0.2s;
  }

  .mario-step-5 {
    animation-duration: 0.1s, 0.6s;
  }

  @keyframes mario-step-2 {
    0% {
      left: 30%;
      bottom: 50px;
    }
    50% {
      left: 41%;
      bottom: 39%;
    }
    100% {
      left: 50%;
      bottom: 50px;
    } 
  }

  .offer_bottom {
    height: 320px;
  }
}

@media (max-width: 500.98px) {
  .offer_bottom {
    height: 210px;
  }
}
</style>
