<template>
  <section class="services" id="about">
    <div class="container">
      <Subtitle2 parent-class="services__desc" data-scroll data-scroll-offset="15%" v-html="subtitle"></Subtitle2>
      <Subtitle1 parent-class="services__desc2" v-html="content.services_description2"></Subtitle1>
      <div class="services__title-wrapper" id="services">
        <Title2
          parent-class="services__title"
          data-scroll
          data-scroll-speed="5"
          data-scroll-direction="horizontal"
          v-html="content.services_title"></Title2>
      </div>
      <ul class="services__advantages">
        <li class="services__item" v-for="item in content.services_list" :key="item.services_list_name">
          <div class="services__item-title fade-custom2" data-scroll
            ><Subtitle2 parent-class="subtitle-2__line" v-html="item.services_list_name"></Subtitle2
          ></div>
          <div class="services__item-desc fade-custom2" data-scroll><Subtitle1 v-html="item.services_list_description"></Subtitle1></div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import Title2 from "@/components/element/Title2App.vue";
import Subtitle1 from "@/components/element/Subtitle1App.vue";
import Subtitle2 from "@/components/element/Subtitle2App.vue";

export default {
  components: {
    Title2,
    Subtitle1,
    Subtitle2,
  },
  data() {
    return {
      content: this.$store.state.data.contentResponse.services,
    };
  },
  computed: {
    subtitle() {
      let text = this.content.services_description.split(" ");
      text = text.map((item) => '<span class="text-element"><span class="slide-custom">' + item + "</span></span>");
      text = text.join(" ");

      return text;
    },
  },
};
</script>

<style>
.services {
  padding-top: 300px;
  padding-bottom: 300px;
  background-color: #b2b2b2;
  background-image: url("@/assets/img/service_bg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  overflow: hidden;
}

.services__desc {
  width: 1025px;
  margin-bottom: 150px;
}

.services__desc2 {
  width: 410px;
  margin-left: auto;
  margin-bottom: 150px;
}

.services__title {
  margin-bottom: 50px;
}

.services__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  overflow: hidden;
}

.services__item:last-child {
  border-bottom: none;
}

.services__title-wrapper {
  transform: translateX(-20vw);
}

.services__item-title {
  flex-basis: 50%;
  padding-right: 10px;
}

.services__item-desc {
  flex-basis: 50%;
  padding-left: 10px;
}

.services__bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;

  width: 100%;
  height: 100%;
}

@media (max-width: 1599.98px) {
  .services {
    padding-top: 250px;
    padding-bottom: 250px;
  }

  .services__desc {
    width: 835px;
    margin-bottom: 130px;
  }

  .services__desc2 {
    width: 425px;
    margin-bottom: 130px;
  }

  .services__title {
    margin-bottom: 30px;
  }
}

@media (max-width: 1199.98px) {
  .services {
    padding-top: 200px;
    padding-bottom: 200px;
  }

  .services__desc {
    width: 692px;
    margin-bottom: 100px;
  }

  .services__desc2 {
    width: 380px;
    margin-bottom: 100px;
  }

  .services__title {
    margin-bottom: 20px;
  }

  .services__item {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

@media (max-width: 991.98px) {
  .services {
    padding-top: 180px;
    padding-bottom: 180px;
  }

  .services__desc {
    width: 490px;
    margin-bottom: 80px;
  }

  .services__desc2 {
    width: 278px;
    margin-bottom: 80px;
  }

  .services__item {
    flex-direction: column;

    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .services__item-title {
    flex-basis: 100%;
    padding-right: 0;
    padding-bottom: 15px;
  }

  .services__item-desc {
    flex-basis: 100%;
    padding-left: 0;
  }
}

@media (max-width: 767.98px) {
  .services {
    padding-top: 160px;
    padding-bottom: 160px;
  }

  .services__desc {
    width: 100%;
  }

  .services__desc2 {
    width: 233px;
  }

  .services__item {
    flex-direction: column;

    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .services__item-title {
    flex-basis: 100%;
    padding-right: 0;
    padding-bottom: 15px;
  }

  .services__item-desc {
    flex-basis: 100%;
    padding-left: 0;
  }
}
</style>
