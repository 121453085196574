import { createStore } from "vuex";
import axios from "axios";

export default createStore({
  state() {
    return {
      preloader: true,
      data: null,
      contactPopup: false,
      projectsPopup: {
        status: false,
        data: null
      }
    };
  },
  mutations: {
    preloaderOff(state) {
      state.preloader = false;
    },
    updateData(state, preload) {
      state.data = preload;
    },
    toggleContactPopup(state, preload) {
      state.contactPopup = preload;
    },
    toggleProjectsPopup(state, preload) {
      state.projectsPopup = preload;
    }
  },
  actions: {
    async loadingData(context) {
      const [menuResponse, contentResponse, mediaResponse] = await Promise.all([
        axios.get(`https://www.prysm.gg/api/wp-json/wp-api-menus/v2/menus/2`),
        axios.get(`https://www.prysm.gg/api/wp-json/wp/v2/pages/186`),
        axios.get(`https://www.prysm.gg/api/wp-json/wp/v2/media?parent=186&per_page=100`),
      ]);

        const transformMedia = {};
        mediaResponse.data.forEach((item) => (transformMedia[item.id] = item));

        context.commit("updateData", {
          menuResponse: menuResponse.data.items,
          contentResponse: contentResponse.data.acf,
          mediaResponse: transformMedia,
        });
    },
    async submitForm(context, payload) {
      let bodyRequest = new FormData();

      for (let key in payload) {
        if (key != "id") {
          bodyRequest.append(key, payload[key]);
        }
      }

      setTimeout(() => {
        context.commit("updateStatusFormPopop");
      }, 400);

      axios({
        method: "post",
        url: `https://www.prysm.gg/api/wp-json/contact-form-7/v1/contact-forms/${payload.id}/feedback`,
        data: bodyRequest,
        headers: { "Content-Type": "multipart/form-data" },
      }).catch(function (error) {
        console.log(error);
      });
    },
  },
});
