<template>
  <footer class="footer">
    <div class="footer__info">
      <a class="footer__policy" :href="content.footer_policy.footer_policy_link" target="_blank">{{ content.footer_policy.footer_policy_name }}</a>
      <p class="footer__сopyright">{{ content.footer_сopyright }}</p>
    </div>
    <div class="footer__contacts">
      <div class="footer__contacts-wrap">
        <a class="footer__linkedin" :href="content.footer_linkedin.footer_linkedin_link" target="_blank">{{ content.footer_linkedin.footer_linkedin_name }}</a>
        <a class="footer__mail" :href="'mailto:' + content.footer_mail" target="_blank">{{ content.footer_mail }}</a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      content: this.$store.state.data.contentResponse.footer,
    };
  },
};
</script>

<style>
.footer {
  display: flex;
  padding: 50px 70px;
  border-top: 1px solid #363636;

  font-size: 16px;
  line-height: 1.5;
  text-transform: uppercase;
  color: #707070;

  background-color: #000000;
}

.footer__info {
  display: flex;
  flex-direction: column;
  width: 33.33%;
}

.footer__policy {
  margin-bottom: 10px;

  text-decoration: none;
  color: #707070;
}

.footer__contacts {
  display: flex;
  justify-content: center;
  width: 33.33%;
}

.footer__contacts-wrap {
  display: flex;
  flex-direction: column;
}

.footer__linkedin {
  margin-bottom: 10px;

  text-decoration: none;
  color: #707070;
}

.footer__mail {
  text-decoration: none;
  color: #707070;
}

.footer__developer {
  width: 33.33%;

  line-height: 1.8;
  text-align: right;
}

.footer__developer-link {
  border-bottom: 1px solid #707070;

  text-decoration: none;
  color: #707070
}

@media (max-width: 1599.98px) {
  .footer {
    padding: 30px 40px;

    font-size: 14px;
  }

  .footer__policy {
    margin-bottom: 8px;
  }

  .footer__linkedin {
    margin-bottom: 8px;
  }
}

@media (max-width: 1199.98px) {
  .footer {
    padding: 30px 30px;

    font-size: 12px;
  }
}

@media (max-width: 991.98px) {
  .footer {
    flex-wrap: wrap;
    padding: 25px 87px;
    
    font-size: 12px;
  }

  .footer__info {
    width: 50%;
    margin-bottom: 30px;
  }

  .footer__contacts {
    justify-content: flex-end;
    width: 50%;
  }

  .footer__developer {
    width: 100%;

    text-align: left;
  }
}

@media (max-width: 767.98px) {
  .footer {
    padding: 25px 15px;
  }

  .footer__info {
    width: 100%;
  }

  .footer__contacts {
    justify-content: flex-start;
    width: 100%;
  }
}
</style>
