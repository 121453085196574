<template>
  <Header />
  <Transition name="contacts-popup">
    <ContactsPopup v-if="this.$store.state.contactPopup" />
  </Transition>
  <Transition name="projects-popup">
    <ProjectsPopup v-if="this.$store.state.projectsPopup.status" />
  </Transition>
  <TopButtom />
  <div class="js-scroll" data-scroll-container>
    <div class="top-point"></div>
    <Offer data-scroll-section />
    <Services data-scroll-section />
    <Projects data-scroll-section />
    <Partners data-scroll-section />
    <Awards data-scroll-section />
    <Contacts data-scroll-section />
    <Footer data-scroll-section />
  </div>
</template>

<script>
import Header from "@/components/section/HeaderApp.vue";
import Offer from "@/components/section/OfferApp.vue";
import Services from "@/components/section/ServicesApp.vue";
import Projects from "@/components/section/ProjectsApp.vue";
import Partners from "@/components/section/PartnersApp.vue";
import Awards from "@/components/section/AwardsApp.vue";
import Contacts from "@/components/section/ContactsApp.vue";
import Footer from "@/components/section/FooterApp.vue";
import ContactsPopup from "@/components/popup/ContactsPopupApp.vue";
import ProjectsPopup from "@/components/popup/ProjectsPopupApp.vue";
import TopButtom from "@/components/element/TopButtomApp.vue";
import LocomotiveScroll from "locomotive-scroll";
import Imagesloaded from "imagesloaded";

export default {
  components: {
    Header,
    Offer,
    Services,
    Projects,
    Partners,
    Awards,
    Contacts,
    Footer,
    ProjectsPopup,
    ContactsPopup,
    TopButtom,
  },
  mounted() {
    let preloaderClose = function () {
      this.$store.commit("preloaderOff");
    }.bind(this);

    Imagesloaded(".js-scroll", function () {
      setTimeout(() => {
        window.scrollCustom = new LocomotiveScroll({
          el: document.querySelector("[data-scroll-container]"),
          smooth: true,
          direction: "vertical",
          lerp: 0.1,
          touchMultiplier: 4,
          mobile: {
            breakpoint: 0,
            smooth: true,
            direction: "vertical",
          },
          tablet: {
            breakpoint: 0,
            smooth: true,
            direction: "vertical",
          },
        });
      }, 190);

      setTimeout(() => preloaderClose(), 200);
    });
  },
};
</script>

<style></style>
