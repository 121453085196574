<template>
  <header class="header" :class="{mobile: menuStatus}" v-scroll>
    <div class="header__mobile">
      <Logo
        :logo="content.header_logo"
        v-if="content.header_logo"
      />
      <Burger parent-class="header__burger" v-on:click="openMenu" />
    </div>
    <Navigation
      parent-class="header__navigation"
      :list="menu"
      v-if="menu"
      v-on:transitionLink="closeMobileMenu"
    />
  </header>
</template>

<script>
import Logo from "@/components/element/LogoApp.vue";
import Navigation from "@/components/block/NavigationApp.vue";
import Burger from "@/components/element/BurgerApp.vue";
import scroll from "@/directives/scroll.js";

export default {
  components: {
    Logo,
    Navigation,
    Burger,
  },
  directives: {
    scroll
  },
  data() {
    return {
      content: this.$store.state.data.contentResponse.header,
      menu: this.$store.state.data.menuResponse,
      menuStatus: false,
    };
  },
  methods: {
    openMenu() {
      this.menuStatus = !this.menuStatus;
      document.querySelector("body").style.overflow = this.menuStatus ? "hidden" : "";
    },
    closeMobileMenu() {
      if (this.menuStatus) {
        this.openMenu()
      }
    },
  },
};
</script>

<style>
.header {
  position: fixed;
  z-index: 999;

  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 70px;
  padding-right: 70px;
}

.header::before {
  content: '';

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;

  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(25px);
}

.header__burger {
  display: none;
}

@media (max-width: 1599.98px) {
  .header {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (max-width: 1199.98px) {
  .header {
    padding-left: 28px;
    padding-right: 28px;
  }

  .header::before {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .header {
    position: static;
    z-index: auto;

    display: block;
    padding: 0;
  }

  .header__mobile {
    position: fixed;
    z-index: 999;

    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 18px;
    padding-bottom: 18px;
    padding-left: 86px;
    padding-right: 86px;
  }

  .header__mobile::before {
    content: '';

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;

    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(25px);

    transition: all 0s 0.8s ease;
  }

  .mobile .header__mobile::before {
    background-color: transparent;
    backdrop-filter: blur(0);

    transition: all 0.1s 0s ease;
  }

  .header__burger {
    display: flex;
  }
}

@media (max-width: 767.98px) {
  .header__mobile {
    padding-left: 15px;
    padding-right: 15px;
  }
}
</style>
