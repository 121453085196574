export function validation(value, quantity) {
  if (quantity) {
    if (value !== quantity) {
      return false;
    }
  } else {
    if (value < 1) {
      return false;
    }
  }

  return true;
}