<template>
<section class="projects" id="projects">
  <div class="container">
    <div class="projects__title-wrapper">
      <Title2 parent-class="projects__title" data-scroll data-scroll-speed="5" data-scroll-direction="horizontal" v-html="content.projects_title"></Title2>
    </div>
    <div class="projects__swiper swiper">
      <ul class="projects__list swiper-wrapper">
        <li class="projects__item swiper-slide" v-for="item in content.projects_list" :key="item.projects_name" v-on:click="openPopup(item)">
          <div class="projects__content">
            <picture>
              <source :srcset="$mediaWp(item.projects_image, 'projects320')" media="(max-width: 767.98px)">
              <source :srcset="$mediaWp(item.projects_image, 'projects991')" media="(max-width: 991.98px)">
              <source :srcset="$mediaWp(item.projects_image, 'projects1199')" media="(max-width: 1199.98px)">
              <source :srcset="$mediaWp(item.projects_image, 'projects1599')" media="(max-width: 1599.98px)">
              <img class="projects__content-img" :src="$mediaWp(item.projects_image, 'projects')" alt="" />
            </picture>
            <div class="projects__description">
              <Subtitle3 v-html="item.projects_description"></Subtitle3>
              <ul class="projects__success">
                <li class="projects__success-item" v-for="subItem in item.projects_success" :key="subItem.projects_success_item">{{ subItem.projects_success_item }}</li>
              </ul>
            </div>
          </div>
          <Subtitle1 parent-class="services__name subtitle-1_big" v-html="item.projects_name"></Subtitle1>
          <div class="services__button">Read more</div>
        </li>
      </ul>
    </div>
    <div class="projects__controls">
      <ButtonSlidePrev parent-class="projects__prev" />
      <ButtonSlideNext parent-class="projects__next" />
    </div>
  </div>
</section>
</template>

<script>
import Swiper, { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import Title2 from "@/components/element/Title2App.vue";
import Subtitle1 from "@/components/element/Subtitle1App.vue";
import Subtitle3 from "@/components/element/Subtitle3App.vue";
import ButtonSlideNext from "@/components/element/ButtonSlideNextApp.vue";
import ButtonSlidePrev from "@/components/element/ButtonSlidePrevApp.vue";

export default {
  components: {
    Title2,
    Subtitle1,
    Subtitle3,
    ButtonSlideNext,
    ButtonSlidePrev,
  },
  data() {
    return {
      content: this.$store.state.data.contentResponse.projects,
    };
  },
  methods: {
    openPopup(item) {
      if (window.innerWidth < '1199.98') {
        let dataProjects = {status: true, data: item};

        this.$store.commit("toggleProjectsPopup", dataProjects);
      }
    }
  },
  mounted() {
    new Swiper(".projects__swiper", {
      modules: [Navigation],
      slidesPerView: "auto",
      speed: 500,
      navigation: {
        nextEl: ".projects__next",
        prevEl: ".projects__prev",
      },
      breakpoints: {
        320: {
          spaceBetween: 15,
        },
        767: {
          spaceBetween: 20,
        },
        992: {
          spaceBetween: 30,
        },
      },
    });
  },
};
</script>

<style>
.projects {
  padding-top: 200px;
  padding-bottom: 200px;

  background-color: #000000;

  overflow: hidden;
}

.projects__title-wrapper {
  transform: translateX(-20vw);
}

.projects__title {
  margin-bottom: 160px;
}

.projects__swiper {
  width: 1510px;
  margin-left: 0;

  overflow: visible;
  user-select: none;
  transition: all 1s ease;
  cursor: url(../../assets/img/Group-8.cur) 20 20, default!important;
}

.projects__item {
  width: 740px;
  touch-action: none;
  overflow: hidden;
}


.projects__content {
  position: relative;

  margin-bottom: 55px;
}

.projects__img {
  width: 100%;
  height: 100%;

  object-fit: cover;
}

.projects__description {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  padding: 70px;

  opacity: 0;

  background-color: rgba(0, 0, 0, 0.55);

  backdrop-filter: blur(25px);
  transition: opacity 0.4s ease-in;
}

.projects__content:hover .projects__description {
  opacity: 1;
}

.projects__success {
  display: flex;
  flex-wrap: wrap;

  margin-right: -40px;
}

.projects__success-item {
  margin-right: 40px;
  margin-bottom: 5px;

  font-size: 18px;
  font-weight: 700;
  line-height: 1.8;
  color: transparent;    

	background-clip: text;
}

.projects__success-item:last-child {
  margin-bottom: 0;
}

.projects__success-item:nth-child(3n+1) {
  background-image: linear-gradient(135deg, #4F8376 20%, #978B63 40%, #F39F71 60%, #ED6476 80%, #E8347B 100%);
}

.projects__success-item:nth-child(3n-1) {
  background-image: linear-gradient(135deg, #EEAC78 20%, #F0967C 40%, #ED5398 60%, #F282B8 80%, #FFFFFF 100%);
}

.projects__success-item:nth-child(3n) {
  background-image: linear-gradient(135deg, #239BA7 20%, #54A09F 40%, #DFC3A6 60%, #F5A180 80%, #F0776D 100%);
}

.services__button {
  display: none;
}

.projects__controls {
  display: none;
}

@media (max-width: 1599.98px) {
  .projects {
    padding-top: 180px;
    padding-bottom: 180px;
  }

  .projects__title {
    margin-bottom: 140px;
  }

  .projects__swiper {
    width: 1252px;
  }

  .projects__item {
    width: 611px;
  }

  .projects__content {
    margin-bottom: 45px;
  }

  .projects__description {
    padding: 55px;
  }

  .projects__success-item {
    font-size: 16px;
  }
}

@media (max-width: 1199.98px) {
  .projects {
    padding-top: 160px;
    padding-bottom: 160px;
  }

  .projects__title {
    margin-bottom: 120px;
  }

  .projects__swiper {
    width: 548px;
    margin-bottom: 60px;
  }

  .projects__item {
    width: 548px;
  }

  .projects__content {
    margin-bottom: 40px;
  }

  .projects__description {
    display: none;
  }

  .services__name {
    margin-bottom: 10px;
  }

  .services__button {
    position: relative;

    display: inline-block;
    padding: 0;
    border: none;

    font-size: 14px;
    line-height: 1.2;
    text-transform: uppercase;
    color: transparent;  
    
    background-image: linear-gradient(160deg, #4F8376 20%, #978B63 40%, #F39F71 60%, #ED6476 80%, #E8347B 100%);
    background-clip: text;  

    cursor: pointer;
    user-select: none;
    appearance: none;
  }

  .services__button::after {
    content: "";

    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;

    height: 1px;

    background-image: linear-gradient(160deg, #4F8376 20%, #978B63 40%, #F39F71 60%, #ED6476 80%, #E8347B 100%);
  }

  .projects__controls {
    display: flex;
    justify-content: space-between;
    width: 115px;
    margin: 0 auto;
  }
}

@media (max-width: 991.98px) {
  .projects__title {
    margin-bottom: 100px;
  }

  .projects__swiper {
    width: 384px;
    margin-bottom: 40px;
  }

  .projects__item {
    width: 384px;
  }

  .projects__content {
    margin-bottom: 30px;
  }

  .projects__controls {
    width: 90px;
  }
}

@media (max-width: 767.98px) {
  .projects {
    padding-top: 140px;
    padding-bottom: 140px;
  }

  .projects__title {
    margin-bottom: 80px;
  }

  .projects__swiper {
    width: 260px;
  }

  .projects__item {
    width: 260px;
  }
}
</style>
