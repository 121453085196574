<template>
  <p :class="classObject">
    <slot />
  </p>
</template>

<script>
export default {
  props: [
    "parentClass"
  ],
  data() {
    return {
      classObject: {
        "subtitle-3": true,
        [this.parentClass]: this.parentClass,
      },
    };
  },
};
</script>

<style>
.subtitle-3 {
  font-size: 16px;
  line-height: 1.8;
}

.subtitle-3 p {
  margin-bottom: 20px;
}

@media (max-width: 1599.98px) {
  .subtitle-3 {
    font-size: 14px;
  }
}

@media (max-width: 991.98px) {
  .subtitle-3 {
    font-size: 12px;
  }
}

</style>