<template>
  <div class="top-button" ref="topButtom" v-on:click="click">  
    <svg class="top-button__icon" width="24" height="15" viewBox="0 0 24 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23 14L12 1L1 14" stroke="white" stroke-linecap="round"/>
    </svg>
  </div>
</template>
<script>
export default {
  methods: {
    click(){
      window.scrollCustom.scrollTo('.offer');
    }
  },
  mounted() {
    let topButtom = document.querySelector(".top-button");
    let previousScroll = 0;


    function scrollTopButton() {
      if (!window.scrollCustom) { 
        setTimeout(scrollTopButton, 500);
        return;
      }

      window.scrollCustom.on('scroll', (e) => {
        if (previousScroll > e.scroll.y && e.scroll.y > 400) {
          topButtom.classList.add("show");
        } else {
          topButtom.classList.remove("show");
        }

        previousScroll = e.scroll.y;
      });
    }

    scrollTopButton();
  },
};
</script>
<style>
.top-button {
  position: fixed;
  bottom: 120px;
  right: 70px;
  z-index: 998;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border: 2px solid #ffffff;
  border-radius: 100%;

  cursor: pointer;
  opacity: 0;
  transform: scale3d(0, 0, 0);
  transition: opacity 0.2s ease-in, transform 0.2s ease-in,
}

.top-button.show {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}

.top-button:hover {
  opacity: 0.6;
}

.top-button__icon {
  width: 20px;
  height: 13px;
}

@media (max-width: 1919.98px) {
  .top-button {
    right: 35px;
  }
}

@media (max-width: 1599.98px) {
  .top-button {
    bottom: 80px;
    right: 35px;
  }
}

@media (max-width: 1199.98px) {
  .top-button {
    bottom: 80px;
    right: 30px;

    width: 60px;
    height: 60px;
  }

  .top-button__icon {
    width: 18px;
    height: 12px;
  }
}

@media (max-width: 767.98px) {
  .top-button {
    right: 20px;
    bottom: 50px;

    width: 50px;
    height: 50px;
  }

  .top-button__icon {
    width: 16px;
    height: 12px;
  }
}
</style>
