<template>
  <nav :class="classObject">
    <ul class="navigation__list">
      <li class="navigation__item" v-for="item in list" :key="item.id">
        <a class="navigation__link" href="#" v-on:click="transition(item.url)" data-interactive >{{
          item.title
        }}</a>
      </li>
    </ul>
    <div class="navigation__footer">
      <a class="navigation__policy" :href="content.footer_policy.footer_policy_link" target="_blank">{{ content.footer_policy.footer_policy_name }}</a>
      <p class="navigation__сopyright">{{ content.footer_сopyright }}</p>
      <a class="navigation__linkedin" :href="content.footer_linkedin.footer_linkedin_link" target="_blank">{{ content.footer_linkedin.footer_linkedin_name }}</a>
      <a class="navigation__email" :href="'mailto:' + content.footer_mail" target="_blank">{{ content.footer_mail }}</a>
    </div>
  </nav>
</template>

<script>
export default {
  props: [
    "list", 
    "parentClass"
  ],
  emits: ['transitionLink'],
  data() {
    return {
      classObject: {
        navigation: true,
        [this.parentClass]: this.parentClass,
      },
      content: this.$store.state.data.contentResponse.footer,
    };
  },
  methods: {
    transition(link) {
      window.scrollCustom.scrollTo(link);
      this.$emit('transitionLink');
    }
  }
};
</script>

<style>
.navigation__list {
  display: flex;
  align-items: center;
}

.navigation__item {
  margin-right: 80px;
}

.navigation__item:last-child {
  margin-right: 0;
}

.navigation__link {
  position: relative;

  display: block;

  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  text-decoration: none;
  color: #000000;

  transition: color 0.2s ease-in;
}

.light .navigation__link {
  color: #ffffff;
}

.navigation__link::before {
  content: '';

  position: absolute;
  left: -20px;
  top: 4px;

  width: 10px;
  height: 10px;

  background-image: url(../../assets/img/item-menu.png);
  background-repeat: no-repeat;
  transform: scale(0);
  transition: transform 0.3s ease;
}

.navigation__link:hover::before {
  transform: scale(1);
}

.navigation__footer {
  display: none;
}

@media (max-width: 1599.98px) {
  .navigation__item {
    margin-right: 50px;
  }

  .navigation__link {
    font-size: 14px;
  }
}

@media (max-width: 1199.98px) {
  .navigation__item {
    margin-right: 40px;
  }
}

@media (max-width: 991.98px) {
  .navigation {
    position: fixed;
    z-index: 990;
    top: 0;
    right: -100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    max-height: 100vh;
    max-height: calc(var(--vh, 1vh) * 100);
    padding-top: 120px;
    padding-left: 86px;
    padding-right: 86px;
    padding-bottom: 35px;

    background-color: #000000;
    
    transform: translate(0, 0);
    transition: transform 0.6s ease;
    overflow-y: auto;
  }

  .mobile .navigation {
    transform: translate(-100%, 0);
  }

  .navigation__list {
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 45px;
  }

  .navigation__item {
    margin-right: 0;
    margin-bottom: 40px;
    overflow: hidden;
  }

  .navigation__item:last-child {
    margin-bottom: 0;
  }

  .navigation__link {
    font-size: 30px;
    color: #ffffff;
    transform: translateY(110%);

    transition: transform 0s 0.6s ease;
  }

  .mobile .navigation__link {
    transform: translateY(0);

    transition: transform 0.9s 0.5s ease;
  }

  .navigation__link::before {
    display: none;
  }

  .navigation__footer {
    display: flex;
    flex-direction: column;

    font-size: 12px;
    text-transform: uppercase;
    line-height: 1.5;
  }

  .navigation__policy {
    margin-bottom: 10px;

    text-decoration: none;
    color: #707070;
  }

  .navigation__сopyright {
    margin-bottom: 30px;

    text-decoration: none;
    color: #707070;
  }

  .navigation__linkedin {
    margin-bottom: 10px;

    text-decoration: none;
    color: #707070;
  }

  .navigation__email {
    text-decoration: none;
    color: #707070;
  }
}

@media (max-width: 767.98px) {
  .navigation {
    padding-left: 15px;
    padding-right: 15px;
  }

  .navigation__link {
    font-size: 24px;
    color: #ffffff;
    transform: translateY(110%);

    transition: transform 0s 0.6s ease;
  }
}
</style>
