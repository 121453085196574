<template>
  <section class="awards">
    <div class="awards__top">
      <div class="awards__top-img-wrapper" data-scroll data-scroll-speed="1.5">
        <picture>
          <source :srcset="$mediaWp(content.awards_bg, 'awards991')" media="(max-width: 991.98px)">
          <source :srcset="$mediaWp(content.awards_bg, 'awards1199')" media="(max-width: 1199.98px)">
          <source :srcset="$mediaWp(content.awards_bg, 'awards1599')" media="(max-width: 1599.98px)">
          <img class="awards__top-img" :src="$mediaWp(content.awards_bg, 'awards')" alt="" />
        </picture>
      </div>
    </div>
    <div class="container">
      <div class="awards__title-wrapper">
        <Title2 parent-class="awards__title" data-scroll data-scroll-speed="5" data-scroll-direction="horizontal">{{ content.awards_title }}</Title2>
      </div>
      <ul class="awards__list">
        <li class="awards__item" v-for="item in content.awards_list" :key="item">
          <img class="awards__img" :src="$mediaWp(item, 'awards_item')">
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import Title2 from "@/components/element/Title2App.vue";

export default {
  components: {
    Title2
  },
  data() {
    return {
      content: this.$store.state.data.contentResponse.awards,
    };
  },
};
</script>

<style>
.awards {
  padding-bottom: 155px;

  background-color: #000000;

  overflow: hidden;
}

.awards__top {
  height: 933px;
  margin-bottom: 150px;

  overflow: hidden;
}

.awards__top-img-wrapper {
  height: 100vh;
}

.awards__top-img {
  width: 90%;
  
  transform: scale(1.4, 1.4);
}

.awards__title-wrapper {
  transform: translateX(-20vw);
}

.awards__title {
  margin-bottom: 100px;
}

.awards__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  justify-items: center;
  align-items: center;
  grid-column-gap: 0px;
  grid-row-gap: 100px;

  user-select: none;
}

.awards__img {
  max-width: 322px;
}

@media (max-width: 1599.98px) {
  .awards {
    padding-bottom: 127px;
  }

  .awards__top {
    height: 683px;
    margin-bottom: 120px;
  }

  .awards__title {
    margin-bottom: 80px;
  }

  .awards__list {
    grid-row-gap: 60px;
  }

  .awards__img {
    max-width: 247px;
  }
}

@media (max-width: 1199.98px) {
  .awards {
    padding-bottom: 100px;
  }

  .awards__top {
    height: 500px;
    margin-bottom: 100px;
  }

  .awards__title {
    margin-bottom: 60px;
  }

  .awards__img {
    max-width: 183px;
  }
}

@media (max-width: 991.98px) {
  .awards {
    padding-bottom: 90px;
  }

  .awards__top {
    height: 450px;
    margin-bottom: 100px;
  }

  .awards__title {
    margin-bottom: 50px;
  }

  .awards__list {
    grid-row-gap: 50px;
  }

  .awards__img {
    max-width: 144px;
  }
}

@media (max-width: 767.98px) {
  .awards {
    padding-bottom: 75px;
  }

  .awards__top {
    height: 350px;
    margin-bottom: 80px;
  }

  .awards__title {
    margin-bottom: 40px;
  }

  .awards__list {
    grid-row-gap: 30px;
  }

  .awards__img {
    max-width: 89px;
  }
}

@media (max-width: 400.98px) {
  .awards__top {
    height: 210px;
  }
}
</style>
